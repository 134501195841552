/*Black */

.question-text-div-black {
    position: absolute; /* Position it on top of the SVG */
    top: 45%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}


.App-header-black{
    position: relative;
}

.question-text-black {
    font-family: SF Comic Script;
    font-size: 4rem;
    color: black;
    margin: 0;
}
/*Blue */

.question-text-div-blue {
    position: absolute; /* Position it on top of the SVG */
    top: 45%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}


.App-header-blue{
    position: relative;
}

.question-text-blue {
    font-family: SF Comic Script;
    font-size: 4rem;
    color: black;
    margin: 0;
}
/*green */

.question-text-div-green {
    position: absolute; /* Position it on top of the SVG */
    top: 45%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}


.App-header-green{
    position: relative;
}

.question-text-green {
    font-family: SF Comic Script;
    font-size: 4rem;
    color: black;
    margin: 0;
}

/*red */

.question-text-div-red {
    position: absolute; /* Position it on top of the SVG */
    top: 45%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}


.App-header-red{
    position: relative;
}

.question-text-red {
    font-family: SF Comic Script;
    font-size: 4rem;
    color: black;
    margin: 0;
}

/*yellow */

.question-text-div-yellow {
    position: absolute; /* Position it on top of the SVG */
    top: 45%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}


.App-header-yellow{
    position: relative;
}

.question-text-yellow {
    font-family: SF Comic Script;
    font-size: 4rem;
    color: black;
    margin: 0;
}
/* This is for black SVG After */

.question-discription-black-div {
    position: absolute; /* Position it on top of the SVG */
    top: 43%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}

.question-discription-black {
    font-size: 2rem;
    color: black;
    margin: 0;
}

.photo-box-black {
    margin-top: 20px;
    text-align: center;
  }

/* This is for green SVG After */

.question-discription-green-div {
    position: absolute; /* Position it on top of the SVG */
    top: 43%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}

.question-discription-green {
    font-size: 2rem;
    color: black;
    margin: 0;
}

.photo-box-green {
    margin-top: 20px;
    text-align: center;
  }
  
  /* This is for blue SVG After */

.question-discription-blue-div {
    position: absolute; /* Position it on top of the SVG */
    top: 43%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}

.question-discription-blue {
    font-size: 2rem;
    color: black;
    margin: 0;
}

.photo-box-blue {
    margin-top: 20px;
    text-align: center;
  }
  
  /* This is for red SVG After */

.question-discription-red-div {
    position: absolute; /* Position it on top of the SVG */
    top: 43%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}

.question-discription-red {
    font-size: 2rem;
    color: black;
    margin: 0;
}

.photo-box-red {
    margin-top: 20px;
    text-align: center;
  }
  
  /* This is for yellow SVG After */

.question-discription-yellow-div {
    position: absolute; /* Position it on top of the SVG */
    top: 43%; /* Adjust as needed */
    width: 80%; /* Adjust based on your design */
    justify-content: center;
    display: flex;
    margin-left: 10%;
    margin-right: 50%;
}

.question-discription-yellow {
    font-size: 2rem;
    color: black;
    margin: 0;
}

.photo-box-yellow {
    margin-top: 20px;
    text-align: center;
  }
  
  

@media only screen and (max-width: 1000px) {
    /*black */
    .question-text-black {
        font-size: 2rem;
        width: 80%;
    }

    .question-discription-black {
        font-size: 0.7rem;
    }

    /* blue */
    .question-text-blue {
        font-size: 2rem;
        width: 80%;
    }

    .question-discription-blue {
        font-size: 0.7rem;
    }
    /* green */
    .question-text-green {
        font-size: 2rem;
        width: 80%;
    }

    .question-discription-green {
        font-size: 0.7rem;
    }
    /* red */
    .question-text-red {
        font-size: 2rem;
        width: 80%;
    }

    .question-discription-red {
        font-size: 0.7rem;
    }
    /* yellow */
    .question-text-yellow {
        font-size: 2rem;
        width: 80%;
    }

    .question-discription-yellow {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 600px) {
    /*black*/
    .question-text-black {
        font-size: 1rem;
        width: 100%;
    }
    .question-discription-black {
        font-size: 0.7rem;
    }
    /*blue*/
    .question-text-blue {
        font-size: 1rem;
        width: 100%;
    }
    .question-discription-blue {
        font-size: 0.7rem;
    }
    /*green*/
    .question-text-green {
        font-size: 1rem;
        width: 100%;
    }
    .question-discription-green {
        font-size: 0.7rem;
    }
    /*red*/
    .question-text-red {
        font-size: 1rem;
        width: 100%;
    }
    .question-discription-red {
        font-size: 0.7rem;
    }
    /*yellow*/
    .question-text-yellow {
        font-size: 1rem;
        width: 100%;
    }
    .question-discription-yellow {
        font-size: 0.7rem;
    }
}